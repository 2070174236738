<!-- =========================================================================================
  File Name: AttachmentEditTabInformation.vue
  Description: Attachment Edit Information Tab content
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/attachment/pixinvent
========================================================================================== -->

<template>
  <div id="attachment-edit-tab-info">
    <div class="vx-row">
      <div class="vx-col w-full md:w-fyll">

        <!-- Col Header -->
        <div class="flex items-end">
          <feather-icon class="mr-2" icon="AttachmentIcon" svgClasses="w-5 h-5"/>
          <span class="leading-none font-medium">Upload Attachment to Storage Server</span>
        </div>

        <div class="vx-row">
          <!-- Col Content -->
          <div class="vx-col w-1/2 mt-6">

            <span class="leading-none font-medium">Select a File to Upload</span>

            <vs-upload ref="uploads" :show-upload-button="false" :single-upload="true"
                       @change="initiateAttachmentSelect"/>
          </div>

          <div class="vx-col w-1/2 mt-6">
            <div class="mt-4">
              <span class="leading-none font-medium">Upload Progress</span>
              <vs-progress :percent="percent" color="primary"></vs-progress>
              <vs-textarea :value="progress" height="215px"></vs-textarea>
            </div>

            <div class="mt-4">

            </div>
          </div>
        </div>
      </div>

    </div>

    <!-- Save & Reset Button -->
    <div class="vx-row">
      <div class="vx-col w-full">
        <div class="mt-8 flex flex-wrap items-center justify-end">
          <vs-button :disabled="disabled" class="ml-auto mt-2" @click="initiateAttachmentUpload">Save Changes
          </vs-button>
          <vs-button :disabled="disabled" class="ml-4 mt-2" color="warning" type="border" @click="resetData">Reset
          </vs-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import vSelect from 'vue-select';
import axios from 'axios';
import https from 'https';

export default {
  components: {
    vSelect,
    flatPickr,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      disabled: false,
      data_local: JSON.parse(JSON.stringify(this.data)),
      selected_attachment: undefined,
      percent: 0,
      progress: '',
    };
  },
  computed: {},
  methods: {
    selectFile() {
      const files = this.$refs.file.files;

      if (files.length > 0) {
        this.file = files[0];
      }

    },
    initiateAttachmentSelect(path, files) {

      files = files.filter(file => !file.remove);

      if (files.length < 1) {

        return this.$vs.notify({
          title: 'Failed to Select Attachment',
          text: 'Please select a attachment to upload.',
          color: 'danger',
        });

      }

      this.selected_attachment = files[0];
      this.progress += `Attachment File Selected...\n`;

    },
    async initiateAttachmentUpload() {
      if (this.selected_attachment === undefined) {
        return this.$vs.notify({
          title: 'Failed to Upload Attachment',
          text: 'Please select a attachment to be uploaded',
          color: 'danger',
        });
      }

      let file = this.selected_attachment;

      const config = {
        onUploadProgress: progressEvent => {

          const bytesUploaded = progressEvent.loaded;
          const bytesTotal = progressEvent.total;

          const percentage = (bytesUploaded / bytesTotal * 100).toFixed(2);
          this.percent = parseFloat(percentage);

        }
      }

      const formData = new FormData();
      formData.append('upload', file);

      this.progress += `Attachment Upload Started...\n`;
      this.$http.post(`attachments/${this.data_local.id}/upload`, formData, config)
        .then((response) => {
          this.$vs.loading.close();

          if (response.data) {

            this.data_local = response.data.data;

            this.progress += `Attachment Upload Completed...\n    Media ID: ${this.data_local.target}\n`;

            this.$vs.notify({
              title: 'Attachment Uploading.',
              text: 'The attachment has been uploaded.',
              color: 'success',
            });

          }

        })
        .catch(e => {
          this.$vs.loading.close();
        });

    },
    resetData() {
      this.data_local = Object.assign({}, this.data);
    },
    uploadFile(url, file) {
      return fetch(url, {
        method: 'PUT',
        body: file,
      })
        .then(resp => {
          return resp.text().then(body => {

            const result = {
              status: resp.status,
              body,
            };

            if (!resp.ok) {
              return Promise.reject(result);
            }

            return result;
          });
        });
    },
  },
};
</script>

<style>

.con-img-upload .img-upload {
  background: rgb(157, 54, 75);
}

</style>
