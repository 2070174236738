<!-- =========================================================================================
  File Name: AttachmentEdit.vue
  Description: Attachment Edit Page
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/attachment/pixinvent
========================================================================================== -->

<template>
  <div id="page-attachment-edit">

    <vs-alert :active.sync="attachment_not_found" color="danger" title="Attachment Not Found">
      <span>Attachment record with id: {{ $route.params.attachmentId }} not found. </span>
      <span>
        <span>Check </span>
        <router-link to="/a/attachments" class="text-inherit underline">All Attachments</router-link>
      </span>
    </vs-alert>

    <vx-card v-if="attachment_data">

      <div class="tabs-container px-6 pt-6" slot="no-body">

        <vs-tabs class="tab-action-btn-fill-conatiner" v-model="activeTab">
          <vs-tab icon="icon-attachment" icon-pack="feather" label="General">
            <div class="tab-text">
              <attachment-edit-tab-general :data="attachment_data" class="mt-4"/>
            </div>
          </vs-tab>
          <vs-tab icon="icon-info" icon-pack="feather" label="Upload Attachment">
            <div class="tab-text">
              <attachment-edit-tab-upload :data="attachment_data" class="mt-4"/>
            </div>
          </vs-tab>
        </vs-tabs>

      </div>

    </vx-card>
  </div>
</template>

<script>
import AttachmentEditTabGeneral from './AttachmentEditTabGeneral';
import AttachmentEditTabUpload from './AttachmentEditTabUpload';

// Store Module

export default {
  components: {
    AttachmentEditTabGeneral,
    AttachmentEditTabUpload,
  },
  data() {
    return {
      attachment_data: null,
      attachment_not_found: false,
      activeTab: 0,
    };
  },
  watch: {
    activeTab() {
      this.fetchAttachmentData(this.$route.params.attachmentId);
    },
  },
  methods: {
    fetchAttachmentData(attachmentId) {

      this.$http.get(`attachments/${attachmentId}`)
        .then(response => {

          if (response.data.data) {
            this.attachment_data = response.data.data;

            const pictureId = this.attachment_data.thumbnail_id || 'default';
            this.attachment_data.photoURL = `https://app-cdn.rapidseminars.com/thumbnails/${pictureId}.jpeg`;
          }

        })
        .catch(error => {
          this.$vs.loading.close();

          if (error.response.status === 404) {
            this.attachment_not_found = true;
            return;
          }
          console.error(error);
        });
    },
  },
  created() {
    this.fetchAttachmentData(this.$route.params.attachmentId);
  },
};

</script>
